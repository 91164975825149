import {Modal, useNotification} from '@hconnect/uikit'
import {useMutation, useIsMutating} from '@tanstack/react-query'
import React from 'react'
import {Controller, FormProvider, SubmitHandler, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Box, Button, CircularProgress, TextField, Typography} from '@mui/material'
import {Theme} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {api} from '../App.global'

interface ReportMissingDataModalFormType {
  comment: string
}

const useStyles = makeStyles((theme: Theme) => ({
  modalInnerBox: {
    width: 'calc(100vw - 30px)',
    maxWidth: 600
  }
}))

export const ReportMissingDataModal: React.FC<{
  open: boolean
  onClose: () => void
}> = ({open = false, onClose}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {notify} = useNotification()
  const mutating = useIsMutating()
  const methods = useForm<ReportMissingDataModalFormType>({
    mode: 'all',
    defaultValues: {
      comment: ''
    }
  })

  const handleClose = () => {
    methods.reset()
    onClose()
  }

  const sendCommentRequest = async (comment: string) => {
    const response = await api.post('/users/reportissue', comment, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
    return response.data
  }

  const {mutate} = useMutation(sendCommentRequest, {
    onSuccess: () => {
      notify('success', t('orderIntake.reportMissingData.success'))
      handleClose()
    },
    onError: () => {
      notify('error', t('orderIntake.reportMissingData.error'))
    }
  })

  const onSubmit: SubmitHandler<ReportMissingDataModalFormType> = (data) => {
    mutate(data.comment)
  }

  return (
    <Modal open={open} onClose={onClose} fitContent haveCloseButton={false}>
      <Box className={classes.modalInnerBox} data-test-id="report-missing-data-modal">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box
              margin={2}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              gap={1}
            >
              <Typography variant="body1">{t('orderIntake.reportMissingData.comment')}</Typography>
              <Controller
                rules={{
                  required: true
                }}
                render={({field, fieldState}) => (
                  <TextField
                    {...field}
                    placeholder={t('orderIntake.reportMissingData.placeholder')}
                    rows={3}
                    maxRows={3}
                    error={!!fieldState.error}
                    multiline
                  />
                )}
                control={methods.control}
                name="comment"
              />
            </Box>

            <Box display="flex" justifyContent="flex-end" mb={3} mr={2} gap={1}>
              <Box width={100}>
                <Button
                  data-test-id="report-missing-data-modal-close-button"
                  fullWidth
                  color="secondary"
                  onClick={handleClose}
                >
                  {mutating ? (
                    <CircularProgress size="small" style={{width: 18, height: 18}} />
                  ) : (
                    t('orderIntake.reportMissingData.close')
                  )}
                </Button>
              </Box>

              <Box width={100}>
                <Button
                  type="submit"
                  data-test-id="report-missing-data-modal-send-button"
                  disabled={!methods.formState.isValid}
                  fullWidth
                  color="secondary"
                >
                  {mutating ? (
                    <CircularProgress size="small" style={{width: 18, height: 18}} />
                  ) : (
                    t('orderIntake.reportMissingData.send')
                  )}
                </Button>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  )
}
